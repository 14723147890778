<template>
  <div class="row mt-2 details mb-1">
    <b-col cols="12">
      <router-link class="btn btn-sm btn-default mb-2" :to="{ name: CustomersPath.name }">
        <em class="fa fa-backward"></em> Manage customer
      </router-link>
    </b-col>
    <b-col lg="12">
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Users" active @click="actionShow('user')">
          <b-col lg="12">
            <b-col v-if="User.length >0">
              <Profile 
              :user="User[0]" 
              :userBannedDetailUserId="userBannedDetailUserId"/>
            </b-col>
            <div v-if="User[0]?.devices.length > 0">
              <TableDevice :User="User" :id="$route.params.id"  :isLoading=isLoading />
            </div>
          </b-col>
          <b-col v-if="User.length > 0">
            <b-card>
              <reading :id="User[0].id" :from="from" :to="to" />
            </b-card>
          </b-col>
        </b-tab>
        <b-tab title="Transaction" @click="showTransaction()">
          <b-col lg="12">

            <div style="max-height:700px">
              <OrderTable :items="items" :id="this.$route.params.id" :isLoading="isLoadingOrders"
                :perPage="this.perPageTransaction" :currentPage="currentPageTransaction" />

              <b-row class="justify-content-start ml-1">
                <b-col lg="3">
                  <b-pagination v-model="currentPageTransaction" :total-rows="rowsTransaction"
                    :per-page="perPageTransaction" aria-controls="my-table" />

                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-tab>
        <b-tab title="Buffets" @click="actionShowBuffet()">
          <b-col lg="12">
            <Buffets :user="this.$route.params.id" :buffets="buffets" :isLoading="false" />
          </b-col>
        </b-tab>
        <b-tab title="Subscription" @click="actionShowSubscription()">
          <b-col lg="12">
            <Subscription :user="this.$route.params.id" :subscription="subscription" :isLoading="false" />
          </b-col>
        </b-tab>
        <b-tab title="Single" @click="actionShowSingle()">
          <b-col lg="12">
            <Single :user="this.$route.params.id" :UserItems="UserItems" :isLoading="false" />
          </b-col>

        </b-tab>
        <b-tab title="Payment Itunes" @click="actionShowDataItunes()">
          <b-alert variant="warning" show>
            This is update report receipt payment itunes
          </b-alert>
          <b-col lg="12">
            <h4 class="h4 text-center"> Lates Payment Itunes</h4>
            <div v-if="itunes.latest_receipt" class="row">
              <b-table :items="itunes.latest_receipt" :busy="isLoadingItunes" show-empty :fields="fields"
                responsive="sm" hover>
                <template #cell(No)="row">
                  {{ row.index + 1 }}
                </template>
              </b-table>
            </div>
          </b-col>
          <b-col lg="12">
            <h4 class="h4 text-center"> History Payment Itunes</h4>
            <div v-if="itunes.latest_receipt">
              <ItunesPayment :items="itunes.receipt.in_app" :isLoading="isLoadingItunes" />
            </div>
          </b-col>
          <hr>
          <b-col lg="12" class="mb-1 pb-1">
            <h4 class="h4 text-center"> Active Renewal </h4>
            <div v-if="itunes.renewalinfo">
              <b-table :items="itunes.renewalinfo" :busy="isLoadingItunes" show-empty responsive="sm" hover>
                <template #cell(No)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(auto_renew_status)="row">
                  <span v-if="row.item.auto_renew_status == '1'" class="badge badge-success">
                    <i class="fa fa-check"></i> Active
                  </span>
                  <span v-else class="badge badge-danger">
                    <i class="fa fa-times"></i> InActive
                  </span>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { CustomersPath } from "../../router/customers";
import moment from "moment";
import Profile from "./profile.vue"
import reading from "./reading.vue"
import Subscription from "./subscription.vue";
import Buffets from "./buffets.vue";
import OrderTable from "../orders/table.vue"
import TableDevice from './components/table.vue'
import Single from "./singleNew.vue"
import ItunesPayment from "./itunes.vue"
export default {
  name: "Usersdetails",
  components: {
    OrderTable,
    Buffets,
    TableDevice,
    reading,
    Subscription,
    Profile,
    ItunesPayment,
    Single
  },
  data() {
    return {
      CustomersPath,
      email: null,
      modal: false,
      perPageTransaction: 10,
      currentPageTransaction: 1,
      date: new Date().toString,
      show: false,
      details: {},
      BuffetsDetail: {},
      loadingAction: false,
      from: "2021-01-01",
      to: moment().format("YYYY-MM-DD"),
      fields: [
        { key: 'No', label: 'No' },
        { key: 'product_id', label: 'Product' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'transaction_id', label: 'Transaction Id' },
        { key: 'original_transaction_id', label: 'Original Transaction Id' },
        { key: 'purchase_date', label: 'Purchase Date', sortable: true, variant: 'info' },
        { key: 'expires_date', label: 'Expire date' },
        { key: 'in_app_ownership_type', label: 'In app' },

      ]
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.users.isLoading,
      isLoadingItunes: (state) => state.users.isLoadingItunes,
      isLoadingOrders: (state) => state.orders.isLoading,
      items: (state) => state.order.items,
      buffets: (state) => state.users.buffets,
      subscription:(state) => state.users.subscription,
      userBannedDetailUserId:(state) => state.users.userBannedDetailUserId ,
      message: (state) => state.buffets.message,
      User: (state) => state.users.User,
      itunes: (state) => state.users.itunes,
      UserItems: (state) => state.users.UserItem,
      rowsTransaction: (state) => state.orders.totalRows,
    }),
    ...mapGetters("orders", { items: "orders" }),
  },
  mounted() {
    let email = this.$route.params.id;
    this.email = email;
    //this.actionGetBuffet();
    this.actionGetUser(email);
    //    this.fetchItunes(email)

  },
  watch: {
    currentPageTransaction: function () {
      this.actionGetOrder();
    },
  },
  methods: {
    ...mapActions("users", ["fetchUserByid", "fetchBuffets", "fetchUserSubscription", "fetchSingle", "fetchItunes"]),
    ...mapActions("orders", ["fetchOrdersByEmail"]),
    ...mapActions("buffets", [
      "postBuffetByOrderID",
      "buffetDelete",
      "updateBuffetByid",
    ]),
    async actionGetOrder() {
      let payload = {
        page: this.currentPageTransaction,
        limit: this.perPageTransaction,
        email: this.$route.params.id,
      };
      this.fetchOrdersByEmail(payload);
    },
    showTransaction() {
      let email = this.$route.params.id;
      this.actionGetOrder(email);
    },
    actionShowDataItunes() {
      let email = this.$route.params.id;
      this.fetchItunes(email)
    },
    actionShowCollection() {
      this.actionGetSingle()
    },
    actionShowSingle() {
      this.actionGetSingle();
    },

    actionShowBuffet() {
      this.actionGetBuffet()
    },
    actionShowSubscription() {
      this.actionSubscription()
    },
    actionGetBuffet() {
      let email = this.$route.params.id;
      let data = {
        email: email,
      };
      this.fetchBuffets(data);
    },
    actionSubscription() {
      let email = this.$route.params.id;
      let data = {
        email: email,
      };
      this.fetchUserSubscription(data);
    },
    actionGetSingle() {
      let email = this.$route.params.id;
      let data = {
        email: email,
        limit: 10,
        page: 1,
        selectAll: true,
        premium: false,
      };
      this.fetchSingle(data)
    },
    actionGetItunes(email) {
      let payload = {
        email: email,
      }
      this.fetchItunes(payload)
    },
    formatDate(tgl) {
      return moment(tgl).format("YYYY MMM DD HH:mm:ss A");
    },
    actionGetUser() {
      let payload = {
        id: this.$route.params.id,
      }
      this.fetchUserByid(payload)

    },
    actionShow() {
      this.actionGetUser()
    },
    formatPrice(value, currency) {
      if (value) {
        let formatter = null;
        if (currency == "USD") {
          formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return "0";
    },
    actionDetail(item) {
      if (this.details) {
        if (this.details.id === item.id) {
          this.show = false;
          this.details = {};
        } else {
          this.show = true;
          this.details = item;
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    showConfirm(line) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {

            //this.ExecutePatchOrder(line);
            this.$swal({
              type: "error",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              title: "Sory this feature is not available Now",
            });
          }
        });
    },
    async ExecutePatchOrder(line) {
      try {
        this.loadingAction = true;
        let payload = {
          id: line.order_id,
          orderline_id: line.id,
        };
        let response = await this.postBuffetByOrderID(payload);
        if (response) {
          this.loadingAction = false;
          this.$swal({
            toast: "true",
            position: "top-end",
            icon: "success",
            title: "Patch successfully",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          this.$swal({
            type: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            title: this.message,
          });
        }
      } catch (err) {
        this.loadingAction = false;
        this.$swal({
          type: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          title: this.message,
        });
      }
    },
    async actionPatchOrder(line) {
      this.showConfirm(line);
    },
    isPremiumPackage(id) {
      const Offers = [
        43103,
        112772,
        44217,
        99551,
        90284,
        98675,
        98703,
        98704,
        112023,
        112024,
        112026,
        112027,
        112028,
        112029,
      ];
      const isValid = Offers.find((e) => e === id);
      return !!(isValid);
    },
  },
};
</script>

<style scoped>
.details {
  font-family: "Avenir Next", "effra", Helvetica, Arial, sans-serif;
}
</style>
